
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import {Link} from 'react-router-dom'
import Breadcrumb from "../Component/Breadcrumb";  
const MandatoryInformation = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Mandatory Public Disclosure" breadcrumbName="Mandatory Public Disclosure" />
           
<div className="container body_height mandatory_dis">
  <div className="row">
    <div className="col-md-12">
    <h3 class="subhead text-center mt-4">APPENDIX – IX : MANDATORY PUBLIC DISCLOSURE</h3>

    <h4>A. GENERAL INFORMATION:</h4>
      <div className="tablescroll">

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>SL NO.</th>
              <th>INFORMATION</th>
              <th>DETAILS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>NAME OF THE SCHOOL</td>
              <td><b>AIR FORCE GOLDEN JUBILEE INSTITUTE</b></td>
              
            </tr>
            <tr>
              <td>2</td>
              <td>Affiliation No</td>
              <td><b>2780009</b></td>
            </tr>
            <tr>
              <td>3</td>
              <td>School Code</td>
              <td><b>27131</b> </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Complete Address with pin code</td>
              <td><b>SUBROTO PARK DELHI CANTT, NEW DELHI – 110 010 </b></td>
            </tr>
            <tr>
              <td>5</td>
              <td>Principal Name and Qualification</td>
              <td><b>ALKA SINGH</b> <br />M.A. M.ED</td>
            </tr>
            <tr>
              <td>6</td>
              <td>School Email ID</td>
              <td><b><a href="mailto: afgjisp@gmail.com">afgjisp@gmail.com</a></b></td>
            </tr>
            <tr>
              <td>7</td>
              <td>Contact Details (Landline/Mobile)</td>
              <td><b>01125692819</b></td>
            </tr>
          

          </tbody>
        </table>
      </div>
      <h4>B. Documents and Information</h4>
      <div className="tablescroll">
        <table className="table table-bordered">
        <thead>
            <tr>
            <th>SL NO.</th>
              <th>Documents/Information</th>
              <th>Upload Documents</th>
            </tr>
          </thead>
          <tbody> 
            <tr>
              <td>1</td>
              <td>Copies of affiliation/upgradation letter and recent extension of affiliation, if any</td>
              <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/Affiliation.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
            <tr>
              <td>2</td>
              <td>Copies of Societies/Trust/Company Registration/Renewal Certificate, as applicable</td>
              <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/society-certificate.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
            <tr>
              <td>3</td>
              <td>Copy of No Objection Certificate(NOC) Issued, if Applicable by the state govt of India</td>
              <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/noc.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
            <tr>
              <td>4</td>
              <td>Copies of recognition certificate under RTE act, 2009, and it's renewal, if applicable</td>
              <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/recognition-certificate.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
            <tr>
              <td>5</td>
              <td>Copy of Valid Building certificate as per the National Building Code</td>
              <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/building-certificate.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
            <tr>
              <td>6</td>
              <td>Copy of valid fire safety certificate issued by the competent authority</td>
              <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/fire-safety-certificate.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
            <tr>
              <td>7</td>
              <td>Copy of the DEO Certificate submitted by the school for affiliation/Upgradation/Extension of affiliation or self-certification by school</td>
              <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/doe.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
            <tr>
              <td>8</td>
              <td>Copies of valid water heath and sanitation certificates</td>
                <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/water-health-sanitization-Certicate.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4>C. RESULTS AND ACADEMICS</h4>
      <div className="tablescroll">
        <table className="table table-bordered">
        <thead>
            <tr>
            <th>SL NO.</th>
              <th>Documents/Information</th>
              <th>Upload Documents</th>
            </tr>
          </thead>
          <tbody> 
            <tr>
              <td>1</td>
              <td>Fee Structure of the school</td>
              <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/fee-structure.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
            <tr>
              <td>2</td>
              <td>Annual Academic Calendar</td>
              <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/academic-calender.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
            <tr>
              <td>3</td>
              <td>List of School Management Committee (SMC)</td>
              <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/smc.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
            <tr>
              <td>4</td>
              <td>List of Parent Teacher Association (PTA) members</td>
              <td><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/pta.pdf" target="_blank"><b>Click Here</b></Link></td>
            </tr>
            <tr>
              <td>5</td>
              <td>Last three years result of the board examination as per applicability</td>
              <td><b><Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/result-X.pdf" target="_blank">Class X</Link>, <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/result-XII.pdf" target="_blank">Class XII</Link></b></td>
            </tr>
            
          </tbody>
        </table>
      </div>
      <h4>Result Class: X</h4>
      <div className="tablescroll">
        <table className="table table-bordered">
        <thead>
            <tr>
            <th>SL. No.</th>
              <th>Year</th>
              <th>No. of registered students</th>
              <th>Number of students pass</th>
              <th>Pass Percentage</th> 
            </tr>
          </thead>
          <tbody> 
            <tr>
              <td>1</td>
              <td>2022</td>
              <td>184</td>
              <td>184</td>
              <td>100%</td>
            </tr>
            <tr>
              <td>2</td>
              <td>2023</td>
              <td>172</td>
              <td>172</td>
              <td>100%</td>
            </tr>
            <tr>
              <td>3</td>
              <td>2024</td>
              <td>154</td>
              <td>154</td>
              <td>100%</td>
            </tr>
            
          </tbody>
        </table>
      </div>
      <h4>Result Class: XII</h4>
      <div className="tablescroll">
        <table className="table table-bordered">
        <thead>
            <tr>
            <th>SL. No.</th>
              <th>Year</th>
              <th>No. of registered students</th>
              <th>Number of students pass</th>
              <th>Pass Percentage</th> 
            </tr>
          </thead>
          <tbody> 
            <tr>
              <td>1</td>
              <td>2022</td>
              <td>153</td>
              <td>153</td>
              <td>100%</td>
            </tr>
            <tr>
              <td>2</td>
              <td>2023</td>
              <td>175</td>
              <td>175</td>
              <td>100%</td>
            </tr>
            <tr>
              <td>3</td>
              <td>2024</td>
              <td>134</td>
              <td>133</td>
              <td>99.25%</td>
            </tr>
            
          </tbody>
        </table>
      </div>
      <h4>D. STAFF(Teaching)</h4>
      <div className="tablescroll">
        <table className="table table-bordered">
        <thead>
            <tr>
            <th>SL. No.</th> 
              <th>Information</th>
              <th>Details</th> 
            </tr>
          </thead>
          <tbody> 
            <tr>
              <td>1</td>
              <td>Principal</td>
              <td><b>1. Mrs. Alka Singh</b><br /> BA | MA | M.Ed |
Experience: 30+ years</td> 
            </tr>
            <tr>
              <td rowSpan={4}>2</td>
              <td colSpan={2}>Total No of Teachers</td> 
            </tr>
            <tr>
              <td>PGT</td>
              <td><b>18</b></td> 
            </tr>
            <tr>
              <td>TGT</td>
              <td><b>27</b></td> 
            </tr>
            <tr>
              <td>PRT</td>
              <td><b>26</b></td> 
            </tr>
            <tr>
              <td>3</td>
              <td>Teacher Section Ratio</td>
              <td><b>1.65:1</b></td> 
            </tr>
            <tr>
              <td>4</td>
              <td>Details of special educator</td>
              <td><b>1. MRS.SABINA OBEROI</b><br/>
B SC | B ED | M ED (SPECIAL EDN)</td> 
            </tr>
            <tr>
              <td>5</td>
              <td>Details of counsellor and wellness teacher</td>
              <td><b>1. MS SRISHTI RANJAN</b><br/>
              MA | PG DIP IN GUIDANCE &
      COUNSELLING <br /> <br /><b>2. MS CHITRALI KASHYAP WADHWA</b><br/>
MA (PSYCHOLOGY)</td> 
            </tr>
          </tbody>
        </table>
      </div>
      <h4>E. SCHOOL INFRASTRUCTURE:</h4>
      <div className="tablescroll">
        <table className="table table-bordered">
        <thead>
            <tr>
            <th>SL. No.</th>
              <th>Information</th>
              <th>Details</th> 
            </tr>
          </thead>
          <tbody> 
            <tr>
              <td>1</td>
              <td>Total Campus Area of the school (In Sq Mtr)</td>
              <td><b>32374.8512</b> Sq Mtr</td>
            </tr>
            <tr>
              <td>2</td>
              <td>No and Size of the classrooms (in sq ft mtr)</td>
              <td><b>70</b> (22.2 x 22.3 Sq Mtr)</td>
            </tr>
           
            <tr>
              <td>3</td>
              <td>No. and Size of laboratories including computer labs (in sq mtr)</td>
              <td><b>11</b> (54.2 x 22.3 Sq Mtr)</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Internet Facility (Y/N)</td>
              <td><b>YES</b></td>
            </tr>
            <tr>
              <td>5</td>
              <td>No. of Girls Toilets</td>
              <td><b>40</b></td>
            </tr>
            <tr>
              <td>6</td>
              <td>No. of Boys Toilets</td>
              <td><b>30</b></td>
            </tr>
            <tr>
              <td>7</td>
              <td>Link of YouTube video of the inspection of school covering the infrastructure of the school.</td>
              <td><b><Link to="https://www.youtube.com/@FuturzClubAFGJI" target="_blank"> https://www.youtube.com/@FuturzClubAFGJI </Link></b></td>
            </tr>          
            
          </tbody>
        </table>
      </div>

      <h4>F. SARAS:</h4>
      <div className="tablescroll">
        <table className="table table-bordered">
        <thead>
            <tr>
            <th>SL. No.</th>
              <th>Information</th>
              <th>Details</th> 
            </tr>
          </thead>
          <tbody> 
            <tr>
              <td>1</td>
              <td>Link to PDF</td>
              <td><a href="https://webapi.entab.info/api/image/AFGJI/public/pdf/Mandatory/mandatory-public-disclosure.pdf" target="_blank">Link</a></td>
            </tr>
                     
            
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
                <Footer />
        </>
    )
}

export default MandatoryInformation