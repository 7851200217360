import { useEffect, useState } from "react";
import { getCircular } from "../Service/Api";
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import { Link } from "react-router-dom"; 
import Breadcrumb from "../Component/Breadcrumb"; 

const Tender = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [yearRanges, setYearRanges] = useState([]); 
  
    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const fiscalYear = `${currentYear}-${currentYear+1}`;
        setSelectedYear(fiscalYear);
    }, []);
  
    useEffect(() => {
        const fetchData = async () => {
            const circularData = await getCircular();
            setData(circularData);
            const fiscalYears = extractFiscalYears(circularData);
            setYearRanges(fiscalYears);
        };
        fetchData();
    }, []);
  
    useEffect(() => {
        if (data.length === 0) return;

        const filteredData = data.filter(item => item.category === "Tender");
        const currentFilteredData = filterDataByFiscalYear(filteredData, selectedYear);

        setFilteredData(currentFilteredData);
    }, [selectedYear, data, yearRanges]);
  
    const filterDataByFiscalYear = (data, fiscalYear) => {
      if (fiscalYear === "All") return data.sort((a, b) => new Date(b.date) - new Date(a.date));
  
      const [startYear, endYear] = fiscalYear.split("-").map(Number);
      const filtered = data.filter(item => {
          if (item.date && item.date.trim() !== "") {
              const itemDate = new Date(item.date);
              const itemYear = itemDate.getFullYear();
              const itemMonth = itemDate.getMonth(); // 0 = January, 11 = December
  
              // Check if the date falls in the fiscal year (April to March)
              if (
                  (itemYear === startYear && itemMonth >= 3) || // April to December of startYear
                  (itemYear === endYear && itemMonth <= 2)     // January to March of endYear
              ) {
                  return true;
              }
          }
          return false;
      });
  
      // Sort the filtered data by date in descending order
      return filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
  };
  
    const extractFiscalYears = (data) => {
        const fiscalYears = data
            .filter(item => item.date && item.date.trim() !== "")
            .map(item => {
                const itemDate = new Date(item.date);
                const itemYear = itemDate.getFullYear();
                const itemMonth = itemDate.getMonth();

                // Determine fiscal year based on the date
                if (itemMonth >= 3) {
                    return `${itemYear}-${itemYear + 1}`;
                } else {
                    return `${itemYear - 1}-${itemYear}`;
                }
            })
            .filter((year, index, self) => self.indexOf(year) === index) // Remove duplicates
            .sort((a, b) => parseInt(b.split("-")[0]) - parseInt(a.split("-")[0])); // Sort descending
        return fiscalYears;
    };

    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Tender" breadcrumbName="Tender" />
            
            <div className="body_height container toppad-30">
                <div className="row">
                    <div className="clr"></div>
                    <div className="col-md-12">
                        <div className="col-xs-6 col-sm-6 col-md-6">
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6">
                            <select 
                                id="ddlYear"
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                            >
                                <option value="All">All</option>
                                {yearRanges.map(yr => (
                                    <option key={yr} value={yr}>
                                        {yr}
                                    </option>
                                ))}
                            </select> 
                        </div>
                        <div className="clr20"></div>

                        <div id="showThree" className="myDiv selected_year">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th width="60%">Title</th>
                                            <th>Date</th>
                                            <th>Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filterData.length > 0 ? (
                                            filterData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.title}</td>
                                                    <td>{item.date}</td>
                                                    <td>
                                                        <Link 
                                                            to={`https://webapi.entab.info/api/image/${item.attachments}`} 
                                                            download 
                                                            target="_blank"
                                                        >
                                                            Download
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Tender;
