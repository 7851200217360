import Footer from "../Component/Footer"
import Header from "../Component/Header"
import LazyLoad from 'react-lazyload';
import { Link } from "react-router-dom";
import Breadcrumb from "../Component/Breadcrumb"; 

const AdmissionList = () =>{
    return(
        <>
        <Header/>
      <Breadcrumb pageTitle="Admission" breadcrumbName="Admission" />

        
<div className="container toppad-30 margin_tb">
     {/* <div className="row">
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-LIST-PART-4-2025-26.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    UKG NAF FOURTH LIST PART 4 OF SELECTED CANDIDATES FOR OPEN SEATS 2025-26 
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-LIST-PART-3-2025-26.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    UKG NAF FOURTH LIST PART 3 OF SELECTED CANDIDATES FOR OPEN SEATS 2025-26 
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-FOURTH-LIST-PART-2-OF-SELECTED-CANDIDATES-FOR-OPEN-SEATS-2025-26.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    UKG NAF FOURTH LIST PART 2 OF SELECTED CANDIDATES FOR OPEN SEATS 2025-26
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/First-List-for-Admission-of-Wards-of-Serving-Air-Force-Personnel-From-UKG-To-X.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    First List for Admission of Wards of Serving Air Force Personnel From UKG To X
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-FOURTH-LIST-OF-SELECTED-CANDIDATES-FOR-OPEN-SEATS-2025-26.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    UKG NAF FOURTH LIST OF SELECTED CANDIDATES FOR OPEN SEATS 2025-26
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-LIST-THIRD-SELECTED-2025-26.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    UKG NAF THIRD LIST OF  SELECTED CANDIDATES FOR OPEN SEATS 2025-26
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-LIST-POINTS-FOR-DRAW-LOTS-2025-26.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    UKG NAF LIST OF 25 POINTS FOR DRAW OF LOTS 2025-26
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-SECOND-LIST-PART-2-SESSION-2025-26.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    UKG NAF SECOND LIST PART 2 SESSION 2025-26
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-Second-LIST-ALONG-WITH-WAITING-LIST-2025-26.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    UKG NAF SECOND LIST ALONG WITH WAITING LIST 2025-26
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-FIRST-LIST-ALONG-WITH-WAITING-LIST-2025-26.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    KG NAF FIRST LIST ALONG WITH WAITING LIST 2025-26
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/LIST-CHILDREN-WHO-APPLIED-ADMISSION-CLASS-UKG-NAF-CATEGORY-POINTS-2025-26.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    LIST OF CHILDREN WHO APPLIED FOR ADMISSION IN CLASS UKG NAF CATEGORY WITH POINTS 2025-26
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/list-applied-admission-for-2025-26.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    LIST OF CHILDREN WHO APPLIED FOR ADMISSION IN CLASS UKG NAF CATEGORY 2025-26
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/admission-notice-for-UKG-XII.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    Admission Notice For Wards Of Serving Air Force Personnal From UKG To XII
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/UKG-NAF-ADMISSION.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    Notification Of UKG NAF Admission(2025-26)
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Fee_Structure.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    Fee Structure: General Wing for the year 2024-25
                </Link>
            </div>
        </div>
        <div className="col-lg-4 col-md-6"> 
            <div className="admission_blk">
                <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/Admission-criteria-SpecialWing.pdf" target="_blank">
                    <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/pdf.png" className="img-fluid" /></LazyLoad>
                    Admission  Criteria &amp; Admission Process (Special Wing) 
                </Link>
            </div>
        </div>
     </div> */}
</div>
<Footer/>
        </>
    )
}
export default AdmissionList