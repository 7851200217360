import React, { useEffect } from 'react';
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from 'react-router-dom'; 
import Breadcrumb from "../Component/Breadcrumb";

const Datesheet = () => {

    useEffect(() => {
        // Ensure Bootstrap's JS is initialized if you're using Bootstrap 5
        const bootstrap = window.bootstrap;
        if (bootstrap) {
            var triggerTabList = [].slice.call(document.querySelectorAll('#events .nav-link'))
            triggerTabList.forEach(function (triggerEl) {
                var tabTrigger = new bootstrap.Tab(triggerEl)

                triggerEl.addEventListener('click', function (event) {
                    event.preventDefault()
                    tabTrigger.show()
                })
            })
        }
    }, []);

    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Datesheet" breadcrumbName="Datesheet" />

            <div className="container body_height">
                <div id="showThree" className="myDiv selected_year">
                    <div className="col-md-3  leftmenu">
                        <div className="leftmenubg">
                            <p className="menuhead">Important Links</p>
                        </div>
                        <div id="events">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active">
                                    <Link to="#datesheets_1" className="nav-link" aria-controls="datesheets_1" role="tab" data-bs-toggle="tab" aria-expanded="false">
                                        Primary Wing Datesheet
                                    </Link>
                                </li>
                                <li role="presentation">
                                    <Link to="#datesheets_2" className="nav-link" aria-controls="datesheets_2" role="tab" data-bs-toggle="tab" aria-expanded="true">
                                        Senior Wing Datesheet
                                    </Link>
                                </li>
                                <li role="presentation">
                                    <Link to="#datesheets_3" className="nav-link" aria-controls="datesheets_3" role="tab" data-bs-toggle="tab" aria-expanded="false">
                                        Special Wing Datesheet
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane active" id="datesheets_1">
                                <h3 class="sub_head">Primary Wing Datesheet</h3>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus">
                                        <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/primary/DateSheet-annual-I-to-V.pdf" target="_blank">
                                        Datesheet for Annual Exam (Grades I to V)</Link> </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus">
                                        <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/primary/DateSheet-PT-II-2024-25.pdf" target="_blank">
                                        DATESHEET FOR PERODIC TEST - II Session 2024-2025 For classes I to V</Link> </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/primary/DATE-SHEET-Mid-Term-2024.pdf" target="_blank">Date Sheet of the Mid-Term exam for classes I to V </Link> </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/primary/DATE-SHEET-PT1-2024.pdf" target="_blank"> PERIODIC TEST - 1 Datesheet SESSION 2024-25 CLASSES I to V  </Link> </div>
                                </div>

                                <div class="cl25"></div>
                            </div>

                            <div role="tabpanel" className="tab-pane" id="datesheets_2">

                                <h3 class="sub_head">Senior Wing Datesheet</h3>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/annual-datesheet-VI-VIII.pdf" target="_blank">Annual Date Sheet For Classes VI to VIII</Link> </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/annual-datesheet-IX-XI.pdf" target="_blank">Annual Date Sheet For Class IX & XI</Link> </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/pre-board-datesheet-X-XII.pdf" target="_blank">Common Pre Board Datesheet For Class X & XII</Link> </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/PERIODIC-TEST-II DATESHEET-OF-CLASSES.pdf" target="_blank">Periodic Test II  for Classes VI-IX and XI</Link> </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/Pre-Board-I-Examination-Datesheet.pdf" target="_blank">Pre Board I Examination Datesheet For Class X and XII </Link> </div>
                                </div>



                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/mid-term-vi-xii-2024-25.pdf" target="_blank">Mid Term  Exam for Classes VI to XII (2024-25)  </Link> </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/periodic-test-i-XI-2024-25.pdf" target="_blank"> PERIODIC TEST- I CLASS XI  SESSION- (2024-25)   </Link> </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/periodic-test-i-XI-2024-25.pdf" target="_blank"> PERIODIC TEST- II  Class X &amp; XII SESSION- (2024-25) </Link> </div>
                                </div>


                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/periodic-test-i-vi-ix-2024-25.pdf" target="_blank"> PERIODIC TEST- I CLASS VI TO IX SESSION- (2024-25) </Link> </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/Secondary/periodic-test-i-X-XII-2024-25.pdf" target="_blank"> Periodic Test - I CLASS X &amp; XII SESSION- (2024-25)  </Link> </div>
                                </div>

                            </div>

                            <div role="tabpanel" className="tab-pane" id="datesheets_3">
                                <h3 class="sub_head">Special Wing Datesheet</h3>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div class="syllabus"> <Link to="https://webapi.entab.info/api/image/AFGJI/public/pdf/datesheet/annual-datesheet-special-wing.pdf" target="_blank">Annual Datesheet Special Wing</Link> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Datesheet;
