
import Header from "../Component/Header";
import Footer from "../Component/Footer"; 
import Breadcrumb from "../Component/Breadcrumb";
import LazyLoad from 'react-lazyload';
const SchoolManagement = () => {
  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Management" breadcrumbName="Management" />

      <div className="container body_height">
        <div className="row">
          <div className="row">
            {/* <div className="col-md-6">
              <div className="team">
                <div className="col-xs-12 col-sm-12 col-md-12"><LazyLoad>
                  <img src="https://webapi.entab.info/api/image/AFGJI/public/Images/management/Air-Marshal-VSM.jpg" className="img-responsive img-circle center-block" alt="AFGJI" /></LazyLoad>
                  </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <center>
                    <h5>Air Marshal RK Anand VSM</h5>
                    <h6><strong>Air Officer-in-Charge Administration</strong> <br /> Chairman Board of Governors </h6>
                  </center>
                </div>
              </div>
            </div> */}
            <div className="col-md-6">
              <div className="team">
                <div className="col-xs-12 col-sm-12 col-md-12">
                <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/management/Air-Marshal.jpg" className="img-responsive img-circle center-block" alt="AFGJI" /></LazyLoad>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <center>
                    <h5>Air Marshal PK Ghosh AVSM</h5>
                    <h6><strong>Air Officer-in-Charge Administration</strong> <br />
                    Chairman, Board of Governors</h6>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="team">
                <div className="col-xs-12 col-sm-12 col-md-12">
                <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/management/Sivakumar.jpg" className="img-responsive img-circle center-block" alt="AFGJI" /></LazyLoad>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <center>
                    <h5>Air Marshal S Sivakumar VSM</h5>
                    <h6><strong>Director General Administration</strong> <br />
                    Senior Vice Chairman, Board of Governors</h6>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="team">
                <div className="col-xs-12 col-sm-12 col-md-12">
                <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/harjit.jpg" className="img-responsive img-circle center-block" alt="AFGJI" /></LazyLoad>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <center>
                    <h5>Air Vice Marshal Harjit S Sidana VSM</h5>
                    <h6><strong>Assistant Chief of Air Staff (Education)</strong> <br /> Executive Vice Chairman, Board of Governors 
                     </h6>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="team">
                <div className="col-xs-12 col-sm-12 col-md-12">
                <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/Prasun.jpg" className="img-responsive img-circle center-block" alt="AFGJI" /></LazyLoad>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <center>
                    <h5>Air Commodore Prasun Basu</h5>
                    <h6><strong>Chairman Management Committee </strong> <br />
                      Air Force Schools</h6>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="team">
                <div className="col-xs-12 col-sm-12 col-md-12">
                <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/management/executive-director.jpg" className="img-responsive img-circle center-block" alt="AFGJI" /></LazyLoad>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <center>
                    <h5> WG CDR ALKA CHAUDHARY</h5>
                    <h6><strong>Executive Director</strong> <br />
                      Air Force Golden Jubilee Institute </h6>
                  </center>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="team">
                <div className="col-xs-12 col-sm-12 col-md-12">
                <LazyLoad><img src="https://webapi.entab.info/api/image/AFGJI/public/Images/management/Principal.jpg" className="img-responsive img-circle center-block" alt="AFGJI" /></LazyLoad>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <center>
                    <h5>Mrs Alka Singh</h5>
                    <h6><strong>Principal</strong> <br />
                      Air Force Golden Jubilee Institute</h6>
                  </center>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SchoolManagement
